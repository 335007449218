import * as React from 'react';
import { Link } from 'gatsby';

import Page from '../components/Page';
import Container from '../components/Container';
import DefaultLayout from '../layout/default';

const IndexPage: React.FC = () => (
  <DefaultLayout>
    <Page>
      <Container>
        <h1>Hi people</h1>
        <p>Welcome to your new Gatsby site.</p>
        <p>Now go build something great.</p>
        <Link to="/page-2/">Go to page 2</Link>
        <form method="POST" action="https://niklsstaticman.herokuapp.com/v2/entry/Nikl-dev/www-nikl-dev/master/comments">
          <input name="options[redirect]" type="hidden" value="http://localhost:8000/thanksforposting" />
          <input name="fields[slug]" type="hidden" value="index" />
          <label>
            <input name="fields[name]" type="text" />
            Name
          </label>
          <label>
            <textarea name="fields[message]" />
            Message
          </label>

          <button type="submit">Go!</button>
        </form>
      </Container>
    </Page>
  </DefaultLayout>
);

export default IndexPage;
